export const API_BASE_URL = "/api";

export const SpeciesType = {
  Fish: 1,
  Invertebrate: 2,
  Plant: 3,
  Coral: 4,
};

export const EnvironmentType = {
  Freshwater: 1,
  Saltwater: 2,
};

export const FreeformType = {
  Freshwater: 1,
  Saltwater: 2,
  "Dry Good": 3,
};

export const ContactPreference = {
  Phone: 1,
  Email: 2,
  SMS: 3,
};

export const LightLevel = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const FlowRate = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const Placement = {
  Front: 1,
  Middle: 2,
  Back: 3,
  "Emersed Only": 4,
  Mounted: 5,
  Floating: 6,
};

export const UserPermissionsLevel = {
  User: 1,
  Admin: 10,
  SuperAdmin: 20,
};

export const OrderItemStatus = {
  Open: 1,
  Ordered: 8,
  "In Quarantine": 2,
  "Needs Contact": 3,
  Holding: 4,
  Sold: 5,
  "Did Not Pickup": 6,
  Cancelled: 7,
};

export const Size = {
  Small: 1,
  Medium: 2,
  Large: 3,
  XLarge: 4,
};

export const CLOVER_LIVESTOCK_CATEGORIES = [
  "RGXJP7SK333JP", // corals
  "EY3135X5CG0H4", // freshwater fish
  "NA75V5R8JJE6E", // freshwater invertebrates
  "77Z66HG8FG7K0", // freshwater plants
  "SGQ3A8AC0AEEJ", // saltwater fish
  "T4PQZZ8Q6H67M", // saltwater Invertebrates
];
