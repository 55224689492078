<template lang="pug">
#order-list
  .mb-1
    h1 Orders
      b-button.ml-2(to="./0", variant="success") Add New
  b-form.mb-3
    h3 Filters
      b-btn.ml-2(@click="resetFilters", variant="secondary") Reset
    b-row
      b-col(cols="12" sm="3") 
        label Search 
        b-form-input(
          v-model="filters.searchQuery",
          placeholder="Search",
          :clearable="true",
        )
      b-col(cols="6" sm="4" md="3")
        label Order Status
        EnumSelect.d-inline(
          v-model="filters.status",
          :enum="OrderItemStatus",
          placeholder="Select Status",
        )
      b-col(cols="6" sm="4" md="3")
        label Assigned Employee 
        UserSelect(
          v-model="filters.user",
          placeholder="Select Employee",
        )
      b-col(cols="6" sm="4" md="3")
        label Species Type 
        EnumSelect(
          v-model="filters.species_type",
          :enum="SpeciesType",
          placeholder="Select Species Type",
        )
      b-col(cols="6" sm="4" md="3")
        label Customer
        CustomerSelect(
          v-model="filters.customer",
          placeholder="Select Customer",
        )
      b-col(cols="6" sm="5" md="6")
        label Order Type
        EnumButtonGroup(
          v-model="filters.freeform_type",
          :enum="FreeformType",
        )
      b-col(cols="6" sm="3" md="2")
        label Misc
        b-form-group
          
          b-form-checkbox(v-model="filters.service" button) Service Customer
            b-icon(v-if="filters.service" icon="check")
  .row
    b-table#table(ref="table" small :items="tableProvider", :fields="fields" striped, dark :perPage="filters.perPage" :current-page="filters.page" sort-by="order.order_date" sort-desc)
      template(#cell(status)="data")
        EnumSelect(
          v-model="data.item.status",
          :enum="OrderItemStatus",
          placeholder="Select Status",
          @input="updateOrderItemStatus(data.item)"
          :clearable="false"
        )
      template(#cell(freeform)="data")
        div
          template(v-if="data.item.clover_item")
            a(:href="'https://www.clover.com/inventory/m/YPVRAH1F15ZXT/items/' + data.item.clover_item.clover_id" target="_blank") {{ data.item.clover_item.name }}
          template(v-else-if="data.item.variant")
            | {{ data.item.variant | formatVariant }} 
          template(v-else)
            | {{ data.item.freeform }} 
        div
          template(v-if="data.item.clover_item")
            b-badge.mr-1(pill variant="info") Dry Good 
          template(v-else-if="data.item.variant")
            b-badge(pill) {{ data.item.variant.species.environment_type | formatEnvironmentType}}
            b-badge.mr-1(pill variant="info") {{ data.item.variant.species.species_type | formatSpeciesType}}
          template(v-else)
            b-badge(pill) {{ data.item.freeform_type | formatEnvironmentType}}
            b-badge.mr-1(pill variant="warning") Freeform 
      template(#cell(actions)="data")
        b-button(:to="`./${data.item.order.id}`", variant="primary") Edit
        //- b-button(@click="deleteorder(data.item)" variant="danger") Delete
  footer
    b-pagination(v-model="filters.page", :total-rows="results.metadata.total", :per-page="filters.perPage")
    p {{ pageFirstRecord }} - {{ pageLastRecord }} of {{ results.metadata.total }}
</template>
<script>
import { mapGetters } from "vuex";
import {
  ORDER_ITEM_LIST_REQUEST,
  ORDER_ITEM_SEARCH_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_ITEM_STATUS_EDIT_REQUEST,
} from "@/store/actions/order";
import EnumSelect from "@/components/select/EnumSelect.vue";
import EnumButtonGroup from "@/components/select/EnumButtonGroup.vue";
import UserSelect from "@/components/select/UserSelect.vue";
import CustomerSelect from "@/components/select/CustomerSelect.vue";
import { formatDate, formatCustomer, formatVariant } from "@/utils";
import {
  OrderItemStatus,
  EnvironmentType,
  FreeformType,
  SpeciesType,
} from "@/constants";

const defaultFilters = () => ({
  searchQuery: "",
  status: OrderItemStatus.Open,
  freeform_type: null,
  user: null,
  service: false,
  customer: null,
  species_type: null,
  page: 1,
  perPage: 25,
});

export default {
  name: "OrderList",
  components: {
    EnumSelect,
    UserSelect,
    CustomerSelect,
    EnumButtonGroup,
  },
  filters: {
    formatVariant,
    formatEnvironmentType(val) {
      return Object.keys(EnvironmentType).find(
        (key) => EnvironmentType[key] === val
      );
    },
    formatSpeciesType(val) {
      return Object.keys(SpeciesType).find((key) => SpeciesType[key] === val);
    },
  },
  data() {
    return {
      results: {
        data: [],
        metadata: {
          total: 0,
          page: 1,
          pages: 1,
          count: 0,
        },
      },
      OrderItemStatus,
      EnvironmentType,
      FreeformType,
      SpeciesType,
      filters: defaultFilters(),
      currentPage: 1,
      fields: [
        {
          key: "order.id",
          label: "ID",
        },
        {
          key: "order.order_date",
          label: "Order Date",
          sortable: true,
          sortDirection: "desc",
          formatter: formatDate,
        },
        {
          key: "quantity",
          label: "QTY",
        },
        {
          key: "freeform",
          label: "Item",
        },
        "status",
        {
          key: "order.customer",
          label: "Customer",
          formatter: formatCustomer,
        },
        "actions",
      ],
    };
  },
  computed: {
    pageFirstRecord() {
      return this.filters.perPage * (this.filters.page - 1) + 1;
    },
    pageLastRecord() {
      return Math.min(
        this.filters.perPage * this.filters.page,
        this.results.metadata.total
      );
    },
    ...mapGetters(["getOrderItems"]),
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        const query = {};
        for (const key in this.filters) {
          if (this.filters[key] !== null) {
            query[key] = this.filters[key];
          }
        }
        //only update if different than existing query
        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.replace({ query });
        }
        this.$root.$emit("bv::refresh::table", "table");
      },
    },
  },
  created() {
    // set filters from query params
    for (const key in this.$route.query) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.filters.hasOwnProperty(key)) {
        if (["service"].includes(key)) {
          this.filters[key] = this.$route.query[key] === "true";
          continue;
        }
        if (key !== "searchQuery" && this.$route.query[key] !== "") {
          this.filters[key] = parseInt(this.$route.query[key]);
        } else {
          this.filters[key] = this.$route.query[key];
        }
      }
    }
    this.search();
  },
  methods: {
    search() {
      return this.$store
        .dispatch(ORDER_ITEM_SEARCH_REQUEST, this.filters)
        .then((resp) => {
          this.results = resp;
          return resp;
        });
    },
    tableProvider(ctx, callback) {
      this.search().then(() => {
        callback(this.results.data);
      });
    },
    updateOrderItemStatus(newItem) {
      this.$store.dispatch(ORDER_ITEM_STATUS_EDIT_REQUEST, {
        id: newItem.id,
        status: newItem.status,
      });
    },
    deleteOrder(order) {
      if (confirm(`Are you sure you want to delete this order?`)) {
        this.$store.dispatch(ORDER_DELETE_REQUEST, order).then(() => {
          this.$store.dispatch(ORDER_ITEM_LIST_REQUEST);
        });
      }
    },
    resetFilters() {
      this.filters = defaultFilters();
    },
  },
};
</script>

<style>
label {
  word-wrap: none;
}
</style>
