<template lang="pug">
#login
  h1 Login
  form.w-50(@submit.prevent="login")
    b-form-group(label="Passcode" label-for="passcode")
      b-form-input(type="password" v-model="passcode" @input="handlePasscodeChange" pattern="[0-9]*")
    b-button(type="submit" variant="primary") Submit

</template>

<script>
import { USER_LOGIN_REQUEST, USER_PROFILE_REQUEST } from "@/store/actions/user";
import { NumericInput } from "numeric-keyboard/dist/numeric_keyboard.vue.js";

export default {
  name: "LoginForm",
  components: {
    NumericInput,
  },
  data() {
    return {
      passcode: "",
    };
  },
  methods: {
    handlePasscodeChange() {
      if (this.passcode.length === 6) {
        this.login();
      }
    },
    login() {
      const { passcode } = this;
      this.passcode = "";
      this.$store.dispatch(USER_LOGIN_REQUEST, { passcode }).then(() => {
        this.$store.dispatch(USER_PROFILE_REQUEST);
        this.$router.push("/");
      });
    },
  },
};
</script>
