import axios from "axios";
import Vue from "vue";
import { API_BASE_URL } from "@/constants";

import {
  ORDER_ITEM_LIST_REQUEST,
  ORDER_ITEM_LIST_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_EDIT_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_ITEM_DELETE_REQUEST,
  ORDER_ITEM_STATUS_EDIT_REQUEST,
  ORDER_LOADING_START,
  ORDER_LOADING_END,
  ORDER_ITEM_SEARCH_REQUEST,
} from "../actions/order";

import { MESSAGE_ADD } from "../actions/messages";

const module = {
  getters: {
    getOrderItems: (state) => state.orders,
    orderStatus: (state) => state.status,
  },
  state: {
    orders: [],
    orderItems: [],
    status: "init",
  },
  actions: {
    [ORDER_ITEM_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/order/items`)
          .then((resp) => {
            const orders = resp.data;
            commit(ORDER_ITEM_LIST_SUCCESS, orders);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
    [ORDER_ITEM_SEARCH_REQUEST]: ({ commit }, filters) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/order/items/search`, {
            params: filters,
          })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
    [ORDER_DETAILS_REQUEST]: ({ commit }, orderId) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);
        axios
          .get(`${API_BASE_URL}/order/${orderId}`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
    [ORDER_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);
        axios
          .post(`${API_BASE_URL}/order/`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Order",
              body: "Order has been successfully saved.",
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
    [ORDER_DELETE_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/order/${data.id}`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Order",
              body: "Order has been successfully deleted.",
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
    [ORDER_ITEM_DELETE_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);
        axios
          .delete(`${API_BASE_URL}/order/items/${data.id}`)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Order Item",
              body: "Order item has been successfully deleted.",
              category: "warning",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
    [ORDER_ITEM_STATUS_EDIT_REQUEST]: ({ commit, dispatch }, data) =>
      new Promise((resolve, reject) => {
        commit(ORDER_LOADING_START);

        axios
          .post(`${API_BASE_URL}/order/items/status/${data.id}`, data)
          .then((resp) => {
            dispatch(MESSAGE_ADD, {
              title: "Order Item",
              body: "Order item status has been successfully updated.",
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => commit(ORDER_LOADING_END));
      }),
  },
  mutations: {
    [ORDER_LOADING_START]: (state) => {
      state.status = "loading";
    },
    [ORDER_LOADING_END]: (state) => {
      state.status = "loaded";
    },
    [ORDER_ITEM_LIST_SUCCESS]: (state, orders) => {
      Vue.set(state, "orders", orders);
    },
  },
};

export default module;
