export const ORDER_ITEM_LIST_REQUEST = "ORDER_ITEM_LIST_REQUEST";
export const ORDER_ITEM_SEARCH_REQUEST = "ORDER_ITEM_SEARCH_REQUEST";
export const ORDER_ITEM_SEARCH_SUCCESS = "ORDER_ITEM_SEARCH_SUCCESS";
export const ORDER_ITEM_LIST_SUCCESS = "ORDER_ITEM_LIST_SUCCESS";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_EDIT_REQUEST = "ORDER_EDIT_REQUEST";
export const ORDER_EDIT_SUCCESS = "ORDER_EDIT_SUCCESS";
export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_ITEM_DELETE_REQUEST = "ORDER_ITEM_DELETE_REQUEST";
export const ORDER_ITEM_STATUS_EDIT_REQUEST = "ORDER_ITEM_STATUS_EDIT_REQUEST";
export const ORDER_LOADING_START = "ORDER_LOADING_START";
export const ORDER_LOADING_END = "ORDER_LOADING_END";
